/* eslint-disable no-underscore-dangle */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { debounce } from "lodash";

import PaginatedTable from "../components/PaginatedTable";
import * as api from "../api";
import { modalActions } from "../state/modal";
import { Form, Select, SubmitButton } from "../components/FormElements";
import { DISASTER_TYPES, ROOM_TYPES } from "../constants";
import { getMacroItem, updateMacroItem } from "../state/macros";
import AutoComplete from "../components/AutoComplete";

export default function MacroItemPage() {
    const { itemId } = useParams();
    const dispatch = useDispatch();

    const { selectedMacroItem, isLoading } = useSelector(
        (state) => state.macros
    );
    const [mappingItemsById, setMappingItemsById] = useState({});

    const mappingItemIds = selectedMacroItem?.mappings?.map(
        (mapping) => mapping.id
    );

    useEffect(() => {
        if (selectedMacroItem) {
            const mappingItemObj = {};
            selectedMacroItem.mappings.forEach((mapping) => {
                mappingItemObj[mapping.id] = mapping;
            });

            setMappingItemsById(mappingItemObj);
        }
    }, [selectedMacroItem]);

    useEffect(() => {
        dispatch(getMacroItem(itemId));
    }, []);

    const getIsLumpCost = () => (rowData) =>
        rowData?.is_lump_cost ? "Yes" : "No";

    const columnNames = [
        "ID",
        "Description",
        "Unit",
        "Material Cost",
        "Labor Cost",
        "Is Lump Cost",
        "Lump Cost",
    ];

    const columns = [
        "id",
        "description",
        "unit",
        "material_cost",
        "labor_cost",
        getIsLumpCost("is_lump_cost"),
        "lump_cost",
    ];

    const onUpdateMacro = async (data) => {
        await dispatch(updateMacroItem(itemId, data));
        dispatch(modalActions.hideModal());
    };

    const handleAddMapping = () => {
        dispatch(
            modalActions.showModal({
                modalType: "UPDATE_MACROS",
                modalProps: {
                    onSave: onUpdateMacro,
                    itemIds: mappingItemIds,
                    item: selectedMacroItem,
                    update: true,
                },
            })
        );
    };

    return (
        <div className="claim-page">
            <div className="claim-header-section d-flex justify-content-between align-items-center">
                <div className="title">
                    <h1 className="h2 mb-0">Macro Item</h1>
                </div>
                <div className="action-buttons">
                    <button
                        type="button"
                        className="btn btn-primary mr-3"
                        onClick={() => handleAddMapping(false)}
                    >
                        Add Line Item
                    </button>
                </div>
            </div>

            <div className="claim-body-section mt-5">
                <div className="">
                    {isLoading ? (
                        <div className="mt-3 d-flex justify-content-center">
                            <div
                                className="spinner-border text-primary"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="d-flex">
                                <h5 className="h5 mb-0">Disaster type: </h5>
                                <h5 className="h5 ml-3">
                                    {selectedMacroItem?.disaster_type}
                                </h5>
                            </div>
                            <div className="d-flex">
                                <h5 className="h5 mb-0">Room type: </h5>
                                <h5 className="h5 ml-3">
                                    {selectedMacroItem?.room_type}
                                </h5>
                            </div>
                            <div className="d-flex">
                                <h5 className="h5 my-3">Line Items</h5>
                            </div>
                            <PaginatedTable
                                columnNames={columnNames}
                                columns={columns}
                                data={mappingItemsById}
                                rows={mappingItemIds}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export function UpdateMacroItemsModal({
    item,
    itemIds,
    onSave,
    update = false,
    disabled,
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [formData] = useState({
        disaster_type: "",
        room_type: "",
        mappings: [],
    });
    const [selectedValues, setSelectedValues] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [itemLength, setItemLength] = useState(0);
    const [nextPage, setNextPage] = useState(1);
    const [searchValue, setSearchValue] = useState(1);

    const onSubmit = (values) => {
        const updatedValues = {
            disaster_type: item ? item.disaster_type : values.disaster_type,
            room_type: item ? item.room_type : values.room_type,
            mappings: [...itemIds, ...selectedValues?.map((value) => value.id)],
        };
        setIsLoading(true);
        onSave(updatedValues);
    };

    const handleSelectValue = (value) => {
        const selectedIds = selectedValues.map(({ id }) => id);
        if (!(selectedIds.includes(value.id) && itemIds.includes(value.id))) {
            const valuesCopy = [...selectedValues];
            valuesCopy.push(value);
            setSelectedValues(valuesCopy);
        }
    };

    const handleSearch = async (value, page) => {
        if (value.length <= 4) {
            setFilteredItems([]);
            return;
        }

        const { data: filteredData } = await api.getMappingItems(
            `?search=${value}&page=${page}`
        );

        const nextPageString = filteredData?.next;

        const newNextPage = nextPageString
            ?.substring(
                nextPageString?.lastIndexOf("?") + 1,
                nextPageString?.lastIndexOf("&")
            )
            .split("page=")[1];

        if (value === searchValue) {
            setFilteredItems([...filteredItems, ...filteredData.results]);
        } else {
            setFilteredItems(filteredData.results);
        }
        setItemLength(filteredData.count);
        setSearchValue(value);
        setNextPage(newNextPage);
    };

    const delayedSearch = debounce(
        (value, page) => handleSearch(value, page),
        300
    );

    return (
        <Form initialValues={formData} onSubmit={onSubmit}>
            <div className="line-item-modal">
                <h4 className="text-center mb-3">
                    {update ? "Line" : "Macro"} Item Details
                </h4>
                {!update && (
                    <>
                        <div className="d-flex details mb-2">
                            <Select
                                label="Disaster type"
                                labelClass="col-4"
                                customClass="col-8"
                                name="disaster_type"
                                placeholder="Disaster type"
                                disabled={disabled}
                                options={Object.keys(DISASTER_TYPES).map(
                                    (type) => ({
                                        value: type,
                                    })
                                )}
                            />
                        </div>
                        <div className="d-flex details mb-2">
                            <Select
                                label="Room type"
                                labelClass="col-4"
                                customClass="col-8"
                                name="room_type"
                                placeholder="Room type"
                                disabled={disabled}
                                options={Object.keys(ROOM_TYPES).map(
                                    (type) => ({
                                        value: type,
                                    })
                                )}
                            />
                        </div>
                    </>
                )}
                <div className="d-flex details mb-2">
                    <AutoComplete
                        className="ml-3 mt-2"
                        length={itemLength}
                        nextPage={nextPage}
                        suggestions={filteredItems}
                        setValue={handleSelectValue}
                        handleSearch={delayedSearch}
                        label="Search mapping Item"
                        clearOnselect
                    />
                </div>
                <ol
                    style={{ maxHeight: "6rem", overflow: "auto" }}
                    className="pl-4"
                >
                    {selectedValues.map((value) => (
                        <li className="mb-0">{value.description}</li>
                    ))}
                </ol>
                <div className="text-center mt-4">
                    <SubmitButton
                        title="Save"
                        className="btn btn-primary px-4"
                        disabled={isLoading || disabled}
                    />
                </div>
            </div>
        </Form>
    );
}
