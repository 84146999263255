import { createSlice } from "@reduxjs/toolkit";
import * as api from "../api";
import { showToastError, showToastSuccess } from "../utils/toastHelper";

const initState = {
    allMacros: {
        allIds: [],
        byId: {},
        count: 0,
    },
    selectedMacro: {
        item: {},
        mappingsByMacroId: {},
    },
    isLoading: true,
};

/* eslint-disable no-param-reassign */
const macrosSlice = createSlice({
    name: "macros",
    initialState: initState,
    reducers: {
        // macros
        setAllMacros(state, { payload }) {
            state.allMacros.allIds = [];
            payload.forEach((macro) => {
                state.allMacros.allIds.push(macro.id);
                state.allMacros.byId[macro.id] = macro;
            });
            state.allMacros.count = payload.length;
        },
        setMacro(state, { payload }) {
            state.allMacros.allIds.push(payload.id);
            state.allMacros.byId[payload.id] = payload;
        },
        updateMacro(state, { payload }) {
            if (state.allMacros.allIds.includes(payload.id)) {
                state.allMacros.byId[payload.id] = payload;
            }
            state.selectedMacroItem = payload;
        },
        setSelectedMacro(state, { payload }) {
            state.selectedMacroItem = payload;
        },

        // loading
        setLoading(state, { payload }) {
            state.isLoading = payload;
        },
    },
});

/* eslint-disable no-param-reassign */
export const macrosActions = macrosSlice.actions;
export const macrosReducer = macrosSlice.reducer;

export function getMacroItems() {
    return async (dispatch) => {
        dispatch(macrosActions.setLoading(true));
        try {
            const response = await api.getMacroItems();
            dispatch(macrosActions.setAllMacros(response.data));
            dispatch(macrosActions.setLoading(false));
        } catch (e) {
            dispatch(showToastError("Error", "Error getting macro item list"));
            dispatch(macrosActions.setLoading(false));
        }
    };
}

export function getMacroItem(itemId) {
    return async (dispatch) => {
        dispatch(macrosActions.setLoading(true));
        try {
            const response = await api.getMacroItems(itemId);

            const mappings = await Promise.all(
                response.data.mappings.map(async (mapping) => {
                    const { data } = await api.getMappingItems(mapping);
                    return data;
                })
            );

            response.data.mappings = mappings;
            dispatch(macrosActions.setSelectedMacro(response.data));
            dispatch(macrosActions.setLoading(false));
        } catch (e) {
            dispatch(showToastError("Error", "Error getting macro item"));
            dispatch(macrosActions.setLoading(false));
        }
    };
}

export function addMacroItem(data) {
    return async (dispatch) => {
        dispatch(macrosActions.setLoading(true));
        try {
            const { status, data: response } = await api.addMacroItem(data);
            if (status === "OK") {
                dispatch(macrosActions.setMacro(response));
                dispatch(
                    showToastSuccess("Success", "Successfully added Macro Item")
                );
                dispatch(macrosActions.setLoading(false));
            } else {
                dispatch(macrosActions.setLoading(false));
                dispatch(showToastError("Error", "Error adding Macro Item"));
            }
        } catch (e) {
            dispatch(macrosActions.setLoading(false));
            dispatch(showToastError("Error", "Error adding Macro Item"));
        }
    };
}

export function updateMacroItem(itemId, data) {
    return async (dispatch) => {
        dispatch(macrosActions.setLoading(true));
        try {
            const { data: response } = await api.updateMacroItem(itemId, data);
            const mappings = await Promise.all(
                response.mappings.map(async (mapping) => {
                    const { data: mappingsData } = await api.getMappingItems(
                        mapping
                    );
                    return mappingsData;
                })
            );

            response.mappings = mappings;
            dispatch(macrosActions.updateMacro(response));
            dispatch(
                showToastSuccess("Success", "Successfully updated Macro Item")
            );
            dispatch(macrosActions.setLoading(false));
        } catch (e) {
            dispatch(macrosActions.setLoading(false));
            dispatch(showToastError("Error", "Error updating Macro Item"));
        }
    };
}
