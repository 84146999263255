import axios from "axios";
import { ACCESS_TOKEN_NAME } from "./config";

// use apiHandler to access protected endpoints
const apiHandler = axios.create({
    baseURL: process.env.REACT_APP_API_BASE,
});

apiHandler.interceptors.request.use((config) => {
    const newConf = config;
    const token = sessionStorage.getItem(ACCESS_TOKEN_NAME);
    newConf.headers.Authorization = `Bearer ${token}`;
    return newConf;
});

apiHandler.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 401) {
            window.location = "/signout";
        } else {
            return Promise.reject(error);
        }
        return 0;
    }
);

// use apiHandler to access public endpoints
// const publicApiHandler = axios.create({
//     baseURL: process.env.REACT_APP_API_BASE,
// });

// auth endpoints

export async function signIn(username, password) {
    const response = await apiHandler.post("/api/auth/token/", {
        username,
        password,
    });
    return response.data;
}

// user endpoints

export async function getUsers() {
    // const response = await apiHandler.get("/api/users/");
    // return response.data;\

    // returning mock data
    return {
        data: [
            {
                id: 1,
                display_name: "John Doe",
                occupation: "CEO. Team motivator.",
                is_admin: false,
                subscription: 3,
            },
            {
                id: 2,
                display_name: "Justin Case",
                occupation: "CTO",
                is_admin: true,
                subscription: 2,
            },
            {
                id: 3,
                display_name: "Ben Dover",
                occupation: "Unemployed",
                is_admin: false,
                subscription: 1,
            },
            {
                id: 4,
                display_name: "Theodore Handle",
                occupation: "Carpenter",
                is_admin: false,
                subscription: 1,
            },
            {
                id: 5,
                display_name: "Will Barrow",
                occupation: "Organic smoothie vendor",
                is_admin: false,
                subscription: 3,
            },
        ],
    };
}

export async function createUser(userData) {
    const response = await apiHandler.post("/api/users/", userData);
    return response.data;
}

export async function updateUser(userId, userData) {
    const response = await apiHandler.put(`/api/users/${userId}`, userData);
    return response.data;
}

export async function getUser(userId) {
    const response = await apiHandler.put(`/api/users/${userId}`);
    return response.data;
}

export async function getMyProfile() {
    // const response = await apiHandler.get(`/api/users/me`);
    // return response.data;
    return {
        data: {
            display_Name: "Test User",
            is_admin: true,
        },
    };
}

// claim endpoints
export async function getClaims() {
    const response = await apiHandler.get("/api/claims/");
    return response.data;
}

export async function createClaim(data) {
    const response = await apiHandler.post("/api/claims/", data);
    return response.data;
}

export async function getClaimRooms(id) {
    const response = await apiHandler.get(`/api/claims/${id}/rooms/`);
    return response.data;
}

export async function getClaimRoomLineItems(claimId, roomId) {
    const response = await apiHandler.get(
        `/api/claims/${claimId}/rooms/${roomId}/line-items/`
    );
    return response.data;
}

export async function analyzeClaim(id) {
    const response = await apiHandler.post("/api/claims/analyze/", {
        claim_id: id,
    });

    return response.data;
}

export async function refetchClaim(id) {
    const response = await apiHandler.get(`/api/claims/${id}/refetch/`);
    return response.data;
}

// mapping endpoints
export async function getMappingItems(data = "") {
    const response = await apiHandler.get(`/api/mapping-items/${data}/`);
    return response.data;
}

export async function addMappingItems(data) {
    const response = await apiHandler.post("/api/mapping-items/", data);
    return response.data;
}

export async function deleteMappingItem(id) {
    const response = await apiHandler.delete(`/api/mapping-items/${id}/`);
    return response.data;
}

export async function addMappingAlias(id, alias) {
    const response = await apiHandler.post(`/api/mapping-items/${id}/alias/`, {
        description: alias,
    });
    return response.data;
}

export async function deleteMappingAlias(mappingId, aliasId) {
    const response = await apiHandler.delete(
        `/api/mapping-items/${mappingId}/alias/${aliasId}/`
    );
    return response.data;
}

export async function deleteMapping(mappingId) {
    const response = await apiHandler.delete(`/api/mappings/${mappingId}/`);
    return response.data;
}

// macro item endpoints
export async function getMacroItems(itemId = "") {
    const response = await apiHandler.get(`/api/macros/${itemId}`);
    return response.data;
}

export async function addMacroItem(data) {
    const response = await apiHandler.post("/api/macros/", data);
    return response.data;
}

export async function updateMacroItem(itemId, data) {
    const response = await apiHandler.put(`/api/macros/${itemId}/`, data);
    return response.data;
}

// model endpoints
export async function getModels(modelId = "") {
    const response = await apiHandler.get(`/api/models/${modelId}`);
    return response.data;
}

export async function addModel(data) {
    const response = await apiHandler.post("/api/models/", data);
    return response.data;
}

export async function deleteModel(modelId) {
    const response = await apiHandler.delete(`/api/models/${modelId}/`);
    return response.data;
}

export async function testModel(modelId, data) {
    const response = await apiHandler.post(
        `/api/models/${modelId}/test/`,
        data
    );
    return response.data;
}

export async function changeModelStatus(modelId, data) {
    const response = await apiHandler.patch(
        `/api/models/${modelId}/status/`,
        data
    );
    return response.data;
}

export async function getModelInfo() {
    const response = await apiHandler.get("/api/info/");
    return response.data;
}
