/* eslint-disable no-undef */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "../state/modal";
import {
    LineItemModal,
    ReplaceLineItemConfirmationModal,
} from "../pages/ClaimRoomPage";
import { MappingsModal } from "../pages/MappingsPage";
import { AddModelModal } from "../pages/ModelPage";
import { UpdateMacroItemsModal } from "../pages/MacroItemPage";
import { ModelConfirmationModal } from "../pages/SingleModelPage";

const MODAL_CONTENT = {
    LINE_ITEMS: LineItemModal,
    MAPPINGS: MappingsModal,
    ADD_MODEL: AddModelModal,
    UPDATE_MACROS: UpdateMacroItemsModal,
    MODEL_CONFIRMATION: ModelConfirmationModal,
    LINE_ITEM_REPLACE_CONFIRMATION: ReplaceLineItemConfirmationModal,
};

export default function ModalContainer() {
    const { modalTypes, modalProps } = useSelector((state) => state.modal);
    const dispatch = useDispatch();

    const handleOverlayKeypress = (e) => {
        // TODO: fix this logic
        if (e.key === "Escape") {
            dispatch(modalActions.hideModal());
        }
    };

    useEffect(() => {
        if (modalTypes.length > 0) {
            document.getElementsByTagName("BODY")[0].style.overflowY = "hidden";
        }

        return () => {
            document.getElementsByTagName("BODY")[0].style.overflowY = "auto";
        };
    }, [modalTypes]);

    return modalTypes.map((modalType) => {
        const CurrModalContent = MODAL_CONTENT[modalType];
        if (!modalTypes.length === 0) return null;
        return (
            <div className="modal-container">
                <div
                    role="presentation"
                    className="modal-overlay"
                    onClick={() => dispatch(modalActions.hideModal())}
                    onKeyPress={handleOverlayKeypress}
                />
                <div className="modal-content">
                    <CurrModalContent {...modalProps[modalType]} />
                </div>
            </div>
        );
    });
}
