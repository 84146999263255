const UNITS = {
    LF: "LF",
    SF: "SF",
    SY: "SY",
    EA: "EA",
    HR: "HR",
};

export const DISASTER_TYPES = {
    WATER: "WATER",
    FLOOD: "FLOOD",
    HURRICANE: "HURRICANE",
    FIRE: "FIRE",
    THEFT: "THEFT",
    OTHER: "OTHER",
};

export const ROOM_TYPES = {
    KITCHEN: "KITCHEN",
    LIVING_ROOM: "LIVING_ROOM",
    DINING_ROOM: "DINING_ROOM",
    FAMILY_ROOM: "FAMILY_ROOM",
    BEDROOM: "BEDROOM",
    HALLWAY: "HALLWAY",
    CLOSET: "CLOSET",
    BATHROOM: "BATHROOM",
    GENERAL_FLOOR_CLEAN_DRYING: "GENERAL_FLOOR_CLEAN_DRYING",
    GENERAL_CONDITIONS: "GENERAL_CONDITIONS",
};

export default UNITS;
