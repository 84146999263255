/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import { useLocation } from "react-router";

import Pagination from "./Pagination";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function PaginatedTable({
    columns,
    columnNames,
    rows,
    count,
    data,
    actions,
    pagination = false,
    selectedRows = [],
    setSelectedRows,
    pageSize = 5,
    selectableRows = false,
    onPageChange = () => {},
    onRowClick = () => {},
}) {
    const query = useQuery();
    const page = _.parseInt(query.get("page"));

    const [currentPage, setCurrentPage] = useState(1);
    const paginatedRows = !pagination
        ? rows?.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        : rows;

    useEffect(() => {
        if (page) {
            setCurrentPage(page);
        }
    }, [page]);

    const [selectAll] = useState(false);

    const getEmptyMarker = () => <div>-</div>;

    return (
        <div className="paginated-table">
            <div className="table-responsive">
                <table className="table mb-0 table-hover">
                    <thead className="thead-light">
                        <tr>
                            {selectableRows && (
                                <th style={{ maxWidth: "3.7rem" }}>
                                    {/* select all */}
                                    <div className="custom-checkbox">
                                        <div className="checkbox">
                                            <input
                                                type="checkbox"
                                                id="select_all_checkbox"
                                                checked={selectAll}
                                            />
                                            <label htmlFor="select_all_checkbox">
                                                {" "}
                                            </label>
                                        </div>
                                    </div>
                                </th>
                            )}
                            {columnNames.map((cn, idx) => (
                                <th key={idx}>
                                    {_.isFunction(cn) ? cn() : cn}
                                </th>
                            ))}
                            {actions && actions.length > 0 && <th>ACTION</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedRows &&
                            paginatedRows.map((rowId) => (
                                <tr
                                    key={rowId}
                                    onClick={() => {
                                        onRowClick(data[rowId]);
                                    }}
                                    className={
                                        selectedRows?.includes(
                                            data[rowId]?.id
                                        ) && "gray"
                                    }
                                >
                                    {selectableRows && (
                                        <td>
                                            <div className="custom-checkbox">
                                                <div className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        id={`table_checkbox${rowId}`}
                                                    />
                                                    <label
                                                        htmlFor={`table_checkbox${rowId}`}
                                                    >
                                                        {" "}
                                                    </label>
                                                </div>
                                            </div>
                                        </td>
                                    )}
                                    {columns.map((c, idx) => (
                                        <td key={idx}>
                                            {_.isFunction(c)
                                                ? c(data[rowId]) ||
                                                  getEmptyMarker()
                                                : _.get(data[rowId], c, null) ||
                                                  getEmptyMarker()}
                                        </td>
                                    ))}
                                    {actions && actions.length > 0 && (
                                        <td>
                                            {actions.map((action, idx) => (
                                                <button
                                                    type="button"
                                                    key={idx}
                                                    className="btn btn-sm btn-outline-primary btn-block"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        action.action(
                                                            data[rowId]
                                                        );
                                                    }}
                                                >
                                                    {action.name}
                                                </button>
                                            ))}
                                        </td>
                                    )}
                                </tr>
                            ))}
                    </tbody>
                </table>
                {count > pageSize && (
                    <Pagination
                        pageSize={pageSize}
                        count={count}
                        currentPage={currentPage}
                        onPageChange={onPageChange}
                    />
                )}
            </div>
        </div>
    );
}
