import React from "react";

import Breadcrumbs from "../components/Breadcrumbs";
import { PrivateRoute } from "./CustomRoutes";
import DashboardPage from "../pages/DashboardPage";
import { UserListPage, UserEditPage } from "../pages/UserPages";
import ClaimPage from "../pages/ClaimPage";
import MappingsPage from "../pages/MappingsPage";
import ClaimRoomPage from "../pages/ClaimRoomPage";
import MappingItemPage from "../pages/MappingItemPage";
import ModelPage from "../pages/ModelPage";
import SingleModelPage from "../pages/SingleModelPage";
import MacroItemPage from "../pages/MacroItemPage";
import MacroPage from "../pages/MacroPage";

const LayoutRoutes = () => (
    <>
        <Breadcrumbs />
        <PrivateRoute component={DashboardPage} exact path="/" />
        <PrivateRoute component={UserListPage} exact path="/users" />
        <PrivateRoute component={UserEditPage} exact path="/users/addUser" />
        <PrivateRoute
            component={UserEditPage}
            exact
            path="/users/editUser/:userId"
        />
        <PrivateRoute component={ClaimPage} exact path="/claims/:claimId" />
        <PrivateRoute
            component={ClaimRoomPage}
            exact
            path="/claims/:claimId/rooms/:roomId/line-items"
        />
        <PrivateRoute component={MappingsPage} exact path="/mapping-items" />
        <PrivateRoute
            component={MappingItemPage}
            exact
            path="/mapping-items/:mappingId"
        />
         <PrivateRoute component={ModelPage} exact path="/models" />
         <PrivateRoute component={SingleModelPage} exact path="/models/:modelId" />
        <PrivateRoute component={MacroPage} exact path="/macro-items" />
        <PrivateRoute
            component={MacroItemPage}
            exact
            path="/macro-items/:itemId"
        />
    </>
);

export default LayoutRoutes;
