/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

export default function AutoComplete({
    suggestions,
    nextPage,
    length,
    setValue,
    label,
    handleSearch,
    clearOnselect,
    className,
}) {
    const [inputValue, setInputValue] = useState("");
    const [selectedSuggestion, setSelectedSuggestion] = useState(null);
    const [displaySuggestions, setDisplaySuggestions] = useState(false);

    const onChange = (event) => {
        const { value } = event.target;
        setInputValue(value);
        handleSearch(value, 1);
        setDisplaySuggestions(true);
    };

    const onSelectSuggestion = (option) => {
        setSelectedSuggestion(option.id);
        setValue(option);
        setInputValue(clearOnselect ? "" : option.description);
        setDisplaySuggestions(false);
    };

    return (
        <div className={`auto-complete ${className}`}>
            <div className="form-group">
                <label htmlFor="search">{label}</label>
                <input
                    name="search"
                    type="search"
                    onChange={onChange}
                    value={inputValue}
                    autoComplete="off"
                    className="form-control"
                />
                <div className="options" id="options">
                    <InfiniteScroll
                        dataLength={length}
                        next={() => handleSearch(inputValue, nextPage)}
                        hasMore={!!nextPage}
                        scrollableTarget="options"
                    >
                        {inputValue &&
                            displaySuggestions &&
                            (suggestions.length > 0 ? (
                                <ul>
                                    {suggestions.map((opt) => (
                                        <li
                                            key={opt.id}
                                            onClick={() =>
                                                onSelectSuggestion(opt)
                                            }
                                        >
                                            {opt.id === selectedSuggestion ? (
                                                <b>{opt.description}</b>
                                            ) : (
                                                <>{opt.description}</>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <div>No suggestions available...</div>
                            ))}
                    </InfiniteScroll>
                </div>
            </div>
        </div>
    );
}
