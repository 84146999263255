import { createSlice } from "@reduxjs/toolkit";
import * as api from "../api";
import { showToastError, showToastSuccess } from "../utils/toastHelper";

const initState = {
    allClaims: {
        allIds: [],
        byId: {},
    },
    claimRooms: {
        allIds: [],
        byId: {},
    },
    claimRoomLineItems: {
        allIds: [],
        byId: {},
    },
    allMappings: {
        allIds: [],
        byId: {},
        count: 0,
        prevPage: "",
        nextPage: "",
    },
    selectedMapping: {},
    allAliases: {
        allIds: [],
        byId: {},
    },
    isLoading: true,
};

/* eslint-disable no-param-reassign */
const claimsSlice = createSlice({
    name: "claims",
    initialState: initState,
    reducers: {
        // claims
        setAllClaims(state, { payload }) {
            state.allClaims.allIds = [];
            payload.forEach((claim) => {
                state.allClaims.allIds.push(claim.id);
                state.allClaims.byId[claim.id] = claim;
            });
        },
        setClaim(state, { payload }) {
            state.allClaims.allIds.push(payload.id);
            state.allClaims.byId[payload.id] = payload;
        },
        removeClaim(state, { payload }) {
            state.allClaims.allIds = state.allClaims.allIds.filter(
                (id) => id !== payload
            );
            delete state.allClaims.byId[payload];
        },

        // claim rooms
        setAllClaimRooms(state, { payload }) {
            state.claimRooms.allIds = [];
            payload.forEach((claimRoom) => {
                state.claimRooms.allIds.push(claimRoom.id);
                state.claimRooms.byId[claimRoom.id] = claimRoom;
            });
        },
        setClaimRoom(state, { payload }) {
            state.claimRooms.allIds.push(payload.id);
            state.claimRooms.byId[payload.id] = payload;
        },
        removeClaimRoom(state, { payload }) {
            state.claimRooms.allIds = state.claimRooms.allIds.filter(
                (id) => id !== payload
            );
            delete state.claimRooms.byId[payload];
        },

        // claim room line items
        setAllClaimRoomLineItems(state, { payload }) {
            state.claimRoomLineItems.allIds = [];
            payload.forEach((claimRoomLineItem) => {
                state.claimRoomLineItems.allIds.push(claimRoomLineItem.id);
                state.claimRoomLineItems.byId[
                    claimRoomLineItem.id
                ] = claimRoomLineItem;
            });
        },
        setClaimRoomLineItem(state, { payload }) {
            state.claimRoomLineItems.allIds.push(payload.id);
            state.claimRoomLineItems.byId[payload.id] = payload;
        },
        removeClaimRoomLineItem(state, { payload }) {
            state.claimRoomLineItems.allIds = state.claimRoomLineItems.allIds.filter(
                (id) => id !== payload
            );
            delete state.claimRoomLineItems.byId[payload];
        },

        // mappings
        setAllMappings(state, { payload }) {
            state.allMappings.allIds = [];
            payload.results.forEach((mapping) => {
                state.allMappings.allIds.push(mapping.id);
                state.allMappings.byId[mapping.id] = mapping;
            });
            state.allMappings.count = payload.count;
            state.allMappings.prevPage = payload.previous;
            state.allMappings.nextPage = payload.next;
        },
        setMapping(state, { payload }) {
            state.allMappings.allIds.push(payload.id);
            state.allMappings.byId[payload.id] = payload;
        },
        setSelectedMapping(state, { payload }) {
            state.selectedMappingItem = payload;

            state.allAliases.allIds = [];
            payload.aliases.forEach((alias) => {
                state.allAliases.allIds.push(alias.id);
                state.allAliases.byId[alias.id] = alias;
            });
        },
        removeMappingAlias(state, { payload }) {
            state.allAliases.allIds = state.allAliases.allIds.filter(
                (id) => id !== payload
            );
            delete state.allAliases.byId[payload];
        },
        removeMapping(state, { payload }) {
            state.allMappings.allIds = state.allMappings.allIds.filter(
                (id) => id !== payload
            );
            delete state.allMappings.byId[payload];
        },

        // loading
        setLoading(state, { payload }) {
            state.isLoading = payload;
        },
    },
});
/* eslint-disable no-param-reassign */

export const claimsActions = claimsSlice.actions;
export const claimsReducer = claimsSlice.reducer;

export function getClaims() {
    return async (dispatch) => {
        dispatch(claimsActions.setLoading(true));
        try {
            const response = await api.getClaims();
            dispatch(claimsActions.setAllClaims(response.data));
            dispatch(claimsActions.setLoading(false));
        } catch (e) {
            dispatch(showToastError("Error", "Error getting claims list"));
            dispatch(claimsActions.setLoading(false));
        }
    };
}

export function getClaimRooms(claimId) {
    return async (dispatch) => {
        dispatch(claimsActions.setLoading(true));
        try {
            const response = await api.getClaimRooms(claimId);
            dispatch(claimsActions.setAllClaimRooms(response.data));
            dispatch(claimsActions.setLoading(false));
        } catch (e) {
            dispatch(showToastError("Error", "Error getting claims list"));
            dispatch(claimsActions.setLoading(false));
        }
    };
}

export function getClaimRoomLineItems(claimId, roomId) {
    return async (dispatch) => {
        dispatch(claimsActions.setLoading(true));
        try {
            const response = await api.getClaimRoomLineItems(claimId, roomId);
            dispatch(claimsActions.setAllClaimRoomLineItems(response.data));
            dispatch(claimsActions.setLoading(false));
        } catch (e) {
            dispatch(showToastError("Error", "Error getting line item list"));
            dispatch(claimsActions.setLoading(false));
        }
    };
}

export function getMappingItems(data) {
    return async (dispatch) => {
        dispatch(claimsActions.setLoading(true));
        try {
            const response = await api.getMappingItems(data);
            dispatch(claimsActions.setAllMappings(response.data));
            dispatch(claimsActions.setLoading(false));
        } catch (e) {
            dispatch(
                showToastError(
                    "No In Training Model",
                    "You need a model in-training to update Mapping Items"
                )
            );
            dispatch(claimsActions.setLoading(false));
        }
    };
}

export function getMappingItem(data) {
    return async (dispatch) => {
        dispatch(claimsActions.setLoading(true));
        try {
            const response = await api.getMappingItems(data);
            dispatch(claimsActions.setSelectedMapping(response.data));
            dispatch(claimsActions.setLoading(false));
        } catch (e) {
            dispatch(showToastError("Error", "Error getting mapping item"));
            dispatch(claimsActions.setLoading(false));
        }
    };
}

export function removeMappingAlias(mappingId, aliasId) {
    return async (dispatch) => {
        dispatch(claimsActions.setLoading(true));
        try {
            await api.deleteMappingAlias(mappingId, aliasId);
            dispatch(claimsActions.removeMappingAlias(aliasId));
            dispatch(showToastSuccess("Success", "Successfully deleted Alias"));
            dispatch(claimsActions.setLoading(false));
        } catch (e) {
            dispatch(claimsActions.setLoading(false));
            dispatch(showToastError("Error", "Error deleting Alias"));
        }
    };
}

export function addMappingItem(data) {
    return async (dispatch) => {
        dispatch(claimsActions.setLoading(true));
        try {
            const { status, data: response } = await api.addMappingItems(data);
            if (status === "OK") {
                dispatch(claimsActions.setMapping(response));
                dispatch(
                    showToastSuccess(
                        "Success",
                        "Successfully added Mapping Item"
                    )
                );
                dispatch(claimsActions.setLoading(false));
            } else {
                dispatch(claimsActions.setLoading(false));
                dispatch(showToastError("Error", "Error adding Mapping Item"));
            }
        } catch (e) {
            dispatch(claimsActions.setLoading(false));
            dispatch(showToastError("Error", "Error adding Mapping Item"));
        }
    };
}

export function removeMappingItem(mappingItemId) {
    return async (dispatch) => {
        dispatch(claimsActions.setLoading(true));
        try {
            await api.deleteMappingItem(mappingItemId);
            dispatch(claimsActions.removeMapping(mappingItemId));
            dispatch(
                showToastSuccess("Success", "Successfully deleted Mapping Item")
            );
            dispatch(claimsActions.setLoading(false));
        } catch (e) {
            dispatch(claimsActions.setLoading(false));
            dispatch(showToastError("Error", "Error deleting Mapping Item"));
        }
    };
}
