import React from "react";
import { useHistory, useLocation } from "react-router";

export default function Pagination(props) {
    const { pageSize, count, currentPage, onPageChange } = props;

    const location = useLocation();

    const history = useHistory();

    const totalPages = Math.ceil(count && count / pageSize);

    const pageNumbers = Array(totalPages)
        .fill()
        .map((_, i) => i + 1);

    const addQuery = (key, value) => {
        const { pathname } = location;
        const searchParams = new URLSearchParams(location.search);
        searchParams.set(key, value);
        history.push({
            pathname,
            search: searchParams.toString(),
        });
    };

    const handlePageClick = (pageNo) => {
        addQuery("page", pageNo);
        onPageChange(pageNo);
    };

    const startIndex = currentPage === 1 ? currentPage - 1 : currentPage - 2;

    const endIndex = currentPage === 1 ? currentPage + 2 : currentPage + 1;

    return (
        <nav>
            <ul className="custom-pagination justify-content-end py-3 pr-3">
                <li
                    className={`page-item  ${
                        currentPage === 1 ? "disabled" : ""
                    }`}
                >
                    <button
                        type="button"
                        className="btn page-link btn-primary"
                        onClick={() => handlePageClick(currentPage - 1)}
                    >
                        &#10094;
                    </button>
                </li>
                <li>
                    <button
                        type="button"
                        className="btn text-decoration-none"
                        style={{ cursor: "default" }}
                    >
                        <i />
                    </button>
                </li>

                {currentPage >= 4 && (
                    <li
                        className={`page-item ${
                            currentPage === 1 ? "active" : ""
                        }`}
                    >
                        <button
                            type="button"
                            className="btn page-link"
                            href="#"
                            onClick={() => handlePageClick(1)}
                        >
                            {1}
                        </button>
                    </li>
                )}

                {pageNumbers.slice(startIndex, endIndex).map((item) => (
                    <li
                        key={item}
                        className={`page-item ${
                            item === currentPage ? "active" : ""
                        }`}
                    >
                        <button
                            type="button"
                            className="btn page-link"
                            onClick={() => handlePageClick(item)}
                        >
                            {item}
                        </button>
                    </li>
                ))}

                <li
                    className={`page-item ${
                        currentPage >= totalPages - 3 || totalPages < 3
                            ? "disabled"
                            : ""
                    }`}
                >
                    <button
                        type="button"
                        className="btn page-link"
                        onClick={() => handlePageClick(currentPage + 4)}
                    >
                        ...
                    </button>
                </li>

                {currentPage <= totalPages - 3 && (
                    <li
                        className={`page-item ${
                            totalPages === currentPage ? "active" : ""
                        }`}
                    >
                        <button
                            type="button"
                            className="btn page-link"
                            href="#"
                            onClick={() => handlePageClick(totalPages)}
                        >
                            {totalPages}
                        </button>
                    </li>
                )}

                <li
                    className={`page-item  ${
                        currentPage === totalPages ? "disabled" : ""
                    }`}
                >
                    <button
                        type="button"
                        className="btn page-link btn-primary"
                        onClick={() => handlePageClick(currentPage + 1)}
                    >
                        &#10095;
                    </button>
                </li>
            </ul>
        </nav>
    );
}
