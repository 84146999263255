import React from "react";
import { useLocation, Link } from "react-router-dom";

const breadcrumbNameMap = {
    "/users": "Users",
    "/users/addUser": "Add User",
    "/users/editUser": "Edit User",
    claims: "Claim",
    "mapping-items": "Mapping Items",
    "macro-items": "Macros",
    "line-items": "Line Items",
    rooms: "Rooms",
    "models": "Models",
};

const Breadcrumbs = () => {
    const location = useLocation();
    const pathNames = location.pathname.split("/").filter((x) => x);

    return (
        <nav aria-label="breadcrumb" className="mb-5">
            <ol className="breadcrumb">
                <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                </li>
                {pathNames.map((value, index) => {
                    const last = index === pathNames.length - 1;
                    const to = `/${pathNames.slice(0, index + 1).join("/")}`;
                    return last ? (
                        <li
                            key={to}
                            className="breadcrumb-item active"
                            aria-current="page"
                        >
                            {breadcrumbNameMap[value] || value}
                        </li>
                    ) : (
                        <li key={to} className="breadcrumb-item">
                            <Link to={to}>
                                {breadcrumbNameMap[value] || value}
                            </Link>
                        </li>
                    );
                })}
            </ol>
        </nav>
    );
};

export default Breadcrumbs;
