/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Form, SubmitButton, TextField } from "../components/FormElements";
import { modalActions } from "../state/modal";
import { changeModelStatus, deleteModel, getModel } from "../state/model";
import getButtonStatus from "../utils/modelUtils";
import { formattedStatus } from "./ModelPage";
import * as api from "../api";
import PaginatedTable from "../components/PaginatedTable";

export default function SingleModelPage() {
    const dispatch = useDispatch();
    const history = useHistory();

    const [currentModel, setCurrentModel] = useState({});
    const [formData, setFormData] = useState({
        line_item: "",
    });

    const { modelId } = useParams();

    const { currentModel: modelData } = useSelector((state) => state.model);
    const { isLoading } = useSelector((state) => state.model);

    const [testResults, setTestResults] = useState({});

    useEffect(() => {
        dispatch(getModel(modelId));
    }, []);

    useEffect(() => {
        setCurrentModel(modelData);
    }, [modelData]);

    const handleChangeStatus = async () => {
        try {
            const action = {
                action: getButtonStatus(currentModel?.status).key,
            };
            await dispatch(changeModelStatus(modelId, action));
            dispatch(getModel(modelId));
            dispatch(modalActions.hideModal());
        } catch (error) {
            console.log(error);
        }
    };

    const handleDelete = async () => {
        try {
            await dispatch(deleteModel(modelId));
            dispatch(modalActions.hideModal());
            history.push("/models");
        } catch (error) {
            console.log(error);
        }
    };

    const onSubmit = async (values) => {
        try {
            const res = await api.testModel(modelId, values);
            setTestResults(
                res.data.results?.reduce(
                    (result, obj, idx) => {
                        result.allIds.push(idx);
                        result.byId[idx] = {
                            item: obj[0],
                            score: obj[1]?.[0] ?? 0,
                        };

                        return result;
                    },
                    { allIds: [], byId: {} }
                )
            );

            setFormData({ line_item: "" });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="single-model-page">
            {isLoading ? (
                <div className="mt-3 d-flex justify-content-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : (
                <>
                    <div className="model-header-section d-flex justify-content-between align-items-center">
                        <div className="title">
                            <h1 className="h2 mb-0">
                                Model v{currentModel?.version}
                            </h1>
                        </div>
                        <div className="action-buttons">
                            <button
                                type="button"
                                className="btn btn-danger mr-2"
                                onClick={() =>
                                    dispatch(
                                        modalActions.showModal({
                                            modalType: "MODEL_CONFIRMATION",
                                            modalProps: {
                                                modalTitle: "Delete Model",
                                                onConfirm: handleDelete,
                                            },
                                        })
                                    )
                                }
                            >
                                Delete
                            </button>
                            {currentModel?.status !== "ARCHIEVED" &&
                                currentModel?.status !== "PROCESSING" && (
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() =>
                                            dispatch(
                                                modalActions.showModal({
                                                    modalType:
                                                        "MODEL_CONFIRMATION",
                                                    modalProps: {
                                                        modalTitle: `${
                                                            getButtonStatus(
                                                                currentModel?.status
                                                            ).buttonState
                                                        } Model`,
                                                        onConfirm: handleChangeStatus,
                                                    },
                                                })
                                            )
                                        }
                                        disabled={!currentModel?.status}
                                    >
                                        {
                                            getButtonStatus(
                                                currentModel?.status
                                            ).buttonState
                                        }
                                    </button>
                                )}
                        </div>
                    </div>
                    <div className="claim-body-section mt-5 col-6">
                        <div className="row">
                            <h5>Version:</h5>
                            <p className="ml-auto font-weight-bold">
                                {currentModel?.version}
                            </p>
                        </div>
                        <div className="row">
                            <h5>Status:</h5>
                            <p className="ml-auto font-weight-bold">
                                {formattedStatus[currentModel?.status]}
                            </p>
                        </div>
                        <div className="row">
                            <h5>Validation Score:</h5>
                            <p className="ml-auto font-weight-bold">as</p>
                        </div>
                        <div className="row">
                            <h5>Created On: </h5>
                            <p className="ml-auto font-weight-bold">
                                {currentModel?.created_date?.split("T")[0]}
                            </p>
                        </div>
                    </div>

                    {currentModel?.status === "ACTIVE" && (
                        <div className="claim-body-section mt-5">
                            <div className="">
                                <div className="title">
                                    <h1 className="h3 mb-4">Test</h1>
                                </div>
                                <div>
                                    <div className="">
                                        <Form
                                            initialValues={formData}
                                            enableReinitialize
                                            onSubmit={onSubmit}
                                        >
                                            <div className="input-group ">
                                                <TextField
                                                    name="line_item"
                                                    type="text"
                                                    placeholder="Enter line item "
                                                />

                                                <div className="input-group-btn">
                                                    <SubmitButton
                                                        className="btn btn-primary ml-4"
                                                        title="Test"
                                                    />
                                                </div>
                                            </div>
                                            {testResults && (
                                                <div className="mt-4">
                                                    <h5>Results</h5>
                                                    <PaginatedTable
                                                        rows={
                                                            testResults.allIds
                                                        }
                                                        columnNames={[
                                                            "Mapping Item",
                                                            "Score",
                                                        ]}
                                                        columns={[
                                                            "item",
                                                            "score",
                                                        ]}
                                                        data={testResults.byId}
                                                    />
                                                </div>
                                            )}
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export function ModelConfirmationModal({ modalTitle, onConfirm }) {
    const dispatch = useDispatch();

    return (
        <div className="confirmation-modal">
            <h4 className="text-center mb-3">{modalTitle}</h4>
            <div className="text-center mt-4">
                <p>Are you sure?</p>
                <div className="d-flex mt-4">
                    <div className="mx-auto">
                        <button
                            className="btn btn-danger px-4"
                            onClick={() => dispatch(modalActions.hideModal())}
                            type="button"
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-primary px-4 ml-2"
                            onClick={onConfirm}
                            type="button"
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
