/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import PaginatedTable from "../components/PaginatedTable";
import { getClaims, getMappingItems } from "../state/claims";

export default function DashboardPage() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { byId, allIds } = useSelector((state) => state.claims.allClaims);
    const { allIds: allMappingIds } = useSelector(
        (state) => state.claims.allMappings
    );
    const { isLoading: spinnerActive } = useSelector((state) => state.claims);

    const [filterQuery, setFilterQuery] = useState("");

    useEffect(() => {
        dispatch(getClaims());
        if (!allMappingIds.length) {
            dispatch(getMappingItems());
        }
    }, []);

    const onChangeFilterQuery = (e) => {
        setFilterQuery(e.target.value);
    };

    const filterData = useMemo(() => {
        const query = filterQuery?.toLowerCase() || null;
        if (query && allIds.length && Object.keys(byId).length !== 0) {
            return allIds.filter((id) => {
                const object = byId[id];
                return (
                    object?.app_environment?.toLowerCase().includes(query) ||
                    object?.date_of_loss?.toLowerCase().includes(query) ||
                    object?.insurer?.toLowerCase().includes(query) ||
                    object?.address?.toLowerCase().includes(query) ||
                    object?.policy_number?.toLowerCase().includes(query) ||
                    object?.firebase_id?.toLowerCase().includes(query) ||
                    object?.claim_number?.toLowerCase().includes(query) ||
                    object?.disaster_type?.toLowerCase().includes(query) ||
                    object?.property_type?.toLowerCase().includes(query) ||
                    object?.status?.toLowerCase().includes(query)
                );
            });
        }
        return allIds;
    }, [filterQuery, allIds, byId]);

    const getData = (data) => (rowData) => rowData[data];

    const getProcessedData = (type) => (rowData) =>
        rowData.processed_meta_data[type];

    const columnNames = [
        "Firebase ID",
        "Date of Loss",
        "Insurer",
        "Address",
        "Policy Number",
        "Claim Number",
        "Disaster Type",
        "Property Type",
        "Total Extracted",
        "Total Mapped",
        "Status",
    ];

    const columns = [
        getData("firebase_id"),
        getData("date_of_loss"),
        getData("insurer"),
        getData("address"),
        getData("policy_number"),
        getData("claim_number"),
        getData("disaster_type"),
        getData("property_type"),
        getProcessedData("total_extracted"),
        getProcessedData("total_mapped"),
        getData("status"),
    ];

    const handleViewClaims = (rowData) => {
        history.push(`/claims/${rowData?.id}`);
    };

    return (
        <div className="dashboard-page">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                <h1 className="h2">Claims</h1>
            </div>

            <div className="row mt-4 filter-search">
                <div className="col-sm-3 pr-sm-0">
                    <div className="form-group position-relative">
                        <input
                            onChange={onChangeFilterQuery}
                            value={filterQuery}
                            type="text"
                            className="form-control"
                            id="search"
                            placeholder="Search"
                        />
                        <img
                            src="/assets/search-icon.png"
                            className="search-img"
                            alt="search"
                        />
                    </div>
                </div>
            </div>
            {spinnerActive ? (
                <div className="mt-3 d-flex justify-content-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : (
                <PaginatedTable
                    columnNames={columnNames}
                    columns={columns}
                    data={byId}
                    rows={filterData}
                    count={allIds.length}
                    onRowClick={handleViewClaims}
                    pageSize={10}
                />
            )}
        </div>
    );
}
