/* eslint-disable no-use-before-define */
import { createSlice } from "@reduxjs/toolkit";
import * as api from "../api";
import { showToastError, showToastSuccess } from "../utils/toastHelper";

const initState = {
    allModels: {
        allIds: [],
        byId: {},
    },
    currentModel: {},
    modelInfo: {},
    isLoading: false,
};

/* eslint-disable no-param-reassign */
const modelSlice = createSlice({
    name: "model",
    initialState: initState,
    reducers: {
        setAllModels(state, { payload }) {
            state.allModels.allIds = [];
            payload.forEach((usr) => {
                state.allModels.allIds.push(usr.id);
                state.allModels.byId[usr.id] = usr;
            });
        },
        setNewModel(state, { payload }) {
            state.allModels.allIds.push(payload.id);
            state.allModels.byId[payload.id] = payload;
        },
        removeModel(state, { payload }) {
            state.allModels.allIds = state.allModels.allIds.filter(
                (id) => id !== payload
            );
            delete state.allModels.byId[payload];
        },
        setCurrentModel(state, { payload }) {
            state.currentModel = payload;
        },
        setModelInfo(state, { payload }) {
            state.modelInfo = payload;
        },
        setLoading(state, { payload }) {
            state.isLoading = payload;
        },
    },
});
/* eslint-disable no-param-reassign */

export const modelActions = modelSlice.actions;
export const modelReducer = modelSlice.reducer;

export function getModels() {
    return async (dispatch) => {
        dispatch(modelActions.setLoading(true));
        try {
            const response = await api.getModels();
            await dispatch(modelActions.setAllModels(response.data));
        } catch (e) {
            dispatch(showToastError("Error", "Error getting Model list"));
        } finally {
            dispatch(modelActions.setLoading(false));
        }
    };
}

export function getModel(modelId) {
    return async (dispatch) => {
        dispatch(modelActions.setLoading(true));
        try {
            const response = await api.getModels(modelId);
            await dispatch(modelActions.setCurrentModel(response.data));
        } catch (e) {
            dispatch(showToastError("Error", "Error getting Model"));
        } finally {
            dispatch(modelActions.setLoading(false));
        }
    };
}

export function changeModelStatus(modelId, action) {
    return async (dispatch) => {
        dispatch(modelActions.setLoading(true));
        try {
            const response = await api.changeModelStatus(modelId, action);
            await dispatch(modelActions.setCurrentModel(response.data));
        } catch (e) {
            dispatch(showToastError("Error", e.response.data.message));
        } finally {
            dispatch(loadModelInfo());
            dispatch(modelActions.setLoading(false));
        }
    };
}

export function deleteModel(modelId) {
    return async (dispatch) => {
        dispatch(modelActions.setLoading(true));
        try {
            await api.deleteModel(modelId);
            await dispatch(modelActions.removeModel(modelId));
        } catch (e) {
            dispatch(showToastError("Error", "Error deleting Model"));
        } finally {
            dispatch(loadModelInfo());
            dispatch(modelActions.setLoading(false));
        }
    };
}

export function addModel(data) {
    return async (dispatch) => {
        dispatch(modelActions.setLoading(true));
        try {
            const { status, data: response } = await api.addModel(data);
            if (status === "OK") {
                await dispatch(modelActions.setNewModel(response));
                dispatch(
                    showToastSuccess("Success", "Successfully added Model")
                );
            } else {
                dispatch(showToastError("Error", "Error adding Model"));
            }
        } catch (e) {
            dispatch(showToastError("Error", "Error adding Model"));
        } finally {
            dispatch(loadModelInfo());
            dispatch(modelActions.setLoading(false));
        }
    };
}

export function loadModelInfo() {
    return async (dispatch) => {
        dispatch(modelActions.setLoading(true));
        try {
            const { data: response } = await api.getModelInfo();
            await dispatch(modelActions.setModelInfo(response));
        } catch (e) {
            dispatch(showToastError("Error", "Error loading Model info"));
        } finally {
            dispatch(modelActions.setLoading(false));
        }
    };
}
