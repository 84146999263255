export default function getModelNextStatus(currentStatus) {
    switch (currentStatus) {
        case "ACTIVE":
            return { buttonState: "Deactivate", key: "DEACTIVATE" };
        case "IN_TRAINING":
            return { buttonState: "Train", key: "TRAIN" };
        case "TRAINED":
            return { buttonState: "Activate", key: "ACTIVATE" };
        default:
            return "";
    }
}
