/* eslint-disable no-underscore-dangle */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import PaginatedTable from "../components/PaginatedTable";
import { modalActions } from "../state/modal";
import { addMacroItem, getMacroItems } from "../state/macros";

export default function MacroPage() {
    const { byId, allIds, count } = useSelector(
        (state) => state.macros.allMacros
    );
    const { isLoading } = useSelector((state) => state.macros);

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(getMacroItems());
    }, []);

    const getLineItemsLength = (rowName) => (rowData) =>
        rowData[rowName].length;

    const columnNames = [
        "ID",
        "Disaster Type",
        "Room Type",
        "No. of Line items",
    ];

    const columns = [
        "id",
        "disaster_type",
        "room_type",
        getLineItemsLength("mappings"),
    ];

    const onAddMacroItem = async (data) => {
        await dispatch(addMacroItem(data));
        dispatch(modalActions.hideModal());
    };

    const handleAddMacroItem = () => {
        dispatch(
            modalActions.showModal({
                modalType: "UPDATE_MACROS",
                modalProps: {
                    onSave: onAddMacroItem,
                    itemIds: [],
                },
            })
        );
    };

    const handleViewMacros = (rowData) => {
        history.push(`macro-items/${rowData.id}`);
    };

    return (
        <div className="claim-page">
            <div className="claim-header-section d-flex justify-content-between align-items-center">
                <div className="title">
                    <h1 className="h2 mb-0">Macros</h1>
                </div>
                <div className="action-buttons">
                    <button
                        type="button"
                        className="btn btn-primary mr-3"
                        onClick={() => handleAddMacroItem()}
                    >
                        Add Macro Item
                    </button>
                </div>
            </div>

            <div className="claim-body-section mt-5">
                <div className="">
                    {isLoading ? (
                        <div className="mt-3 d-flex justify-content-center">
                            <div
                                className="spinner-border text-primary"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <PaginatedTable
                            columnNames={columnNames}
                            columns={columns}
                            data={byId}
                            rows={allIds}
                            count={count}
                            pageSize={20}
                            onRowClick={handleViewMacros}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
