import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { reducer as toastrReducer } from "react-redux-toastr";
import { authReducer } from "./auth";
import { userReducer } from "./user";
import { modalReducer } from "./modal";

// rtk-query demo
import appService from "../services/appService";
import userService from "../services/userService";
import { claimsReducer } from "./claims";
import { modelReducer } from "./model";
import { macrosReducer } from "./macros";

export default configureStore({
    reducer: combineReducers({
        toastr: toastrReducer,
        auth: authReducer,
        user: userReducer,
        modal: modalReducer,
        claims: claimsReducer,
        model: modelReducer,
        macros: macrosReducer,
        [appService.reducerPath]: appService.reducer,
        [userService.reducerPath]: userService.reducer,
    }),
});
