/* eslint-disable no-underscore-dangle */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";

import { debounce } from "lodash";
import PaginatedTable from "../components/PaginatedTable";
import { modalActions } from "../state/modal";
import { addMappingItem, getMappingItems } from "../state/claims";
import {
    Form,
    Select,
    SubmitButton,
    TextArea,
    TextField,
    YesNoRadio,
} from "../components/FormElements";
import UNITS from "../constants";

export default function MappingsPage() {
    const { byId, allIds, count } = useSelector(
        (state) => state.claims.allMappings
    );
    const { isLoading } = useSelector((state) => state.claims);

    const searchParams = new URLSearchParams(useLocation().search);
    const page = searchParams.get("page");

    const dispatch = useDispatch();
    const history = useHistory();

    const handlePageChange = (pageNumber) => {
        dispatch(
            getMappingItems(`?status=IN_TRAINING&page=${pageNumber || 1}`)
        );
    };

    const handleSearch = async (value) => {
        if (value.length === 0) {
            handlePageChange();
        }

        if (value.length <= 4) {
            return;
        }

        dispatch(getMappingItems(`?search=${value}&status=IN_TRAINING`));
    };

    const delayedSearch = debounce((value) => handleSearch(value), 300);

    useEffect(() => {
        handlePageChange(page);
    }, []);

    const getIsLumpCost = () => (rowData) =>
        rowData?.is_lump_cost ? "Yes" : "No";

    const columnNames = [
        "ID",
        "Description",
        "Unit",
        "Material Cost",
        "Labor Cost",
        "Is Lump Cost",
        "Lump Cost",
    ];

    const columns = [
        "id",
        "description",
        "unit",
        "material_cost",
        "labor_cost",
        getIsLumpCost("is_lump_cost"),
        "lump_cost",
    ];

    const onAddMapping = async (data) => {
        await dispatch(addMappingItem(data));
        dispatch(modalActions.hideModal());
    };

    const handleAddMapping = (bulk) => {
        dispatch(
            modalActions.showModal({
                modalType: "MAPPINGS",
                modalProps: {
                    onSave: onAddMapping,
                    bulk,
                },
            })
        );
    };

    const handleViewMappings = (rowData) => {
        history.push(`mapping-items/${rowData.id}`);
    };

    return (
        <div className="claim-page">
            <div className="claim-header-section d-flex justify-content-between align-items-center">
                <div className="title">
                    <h1 className="h2 mb-0">Mappings</h1>
                </div>
                <div className="action-buttons">
                    <button
                        type="button"
                        className="btn btn-primary mr-3"
                        onClick={() => handleAddMapping(false)}
                    >
                        Add Mapping Item
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary mr-3"
                        onClick={() => handleAddMapping(true)}
                    >
                        Add Bulk Mapping Items
                    </button>
                </div>
            </div>
            <div className="claim-body-section mt-5">
                <div className="row mt-4 filter-search">
                    <div className="col-sm-3 pr-sm-0">
                        <div className="form-group position-relative">
                            <input
                                onChange={(e) => delayedSearch(e.target.value)}
                                type="text"
                                className="form-control"
                                id="search"
                                placeholder="Search"
                            />
                            <img
                                src="/assets/search-icon.png"
                                className="search-img"
                                alt="search"
                            />
                        </div>
                    </div>
                </div>
                <div className="">
                    {isLoading ? (
                        <div className="mt-3 d-flex justify-content-center">
                            <div
                                className="spinner-border text-primary"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <PaginatedTable
                            columnNames={columnNames}
                            columns={columns}
                            data={byId}
                            rows={allIds}
                            count={count}
                            onRowClick={handleViewMappings}
                            pagination
                            pageSize={20}
                            onPageChange={handlePageChange}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export function MappingsModal({
    data,
    onSave,
    onDeleteMapping,
    disabled,
    bulk,
}) {
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = (values) => {
        if (bulk) {
            const value = JSON.parse(values.mapping_items);
            onSave(value);
        } else {
            const updatedValues = {
                description: values.description,
                material_cost: values.material_cost ?? 0,
                labor_cost: values.labor_cost ?? 0,
                is_lump_cost: values.is_lump_cost ?? false,
                lump_cost: values.lump_cost ?? 0,
                unit: values.unit,
                aliases: [],
            };
            setIsLoading(true);
            if (onDeleteMapping) {
                onSave(data.id, updatedValues);
            } else {
                onSave(updatedValues);
            }
        }
    };

    const onDelete = () => {
        setIsLoading(true);
        onDeleteMapping(data.id);
    };

    return (
        <Form initialValues={{ ...data }} onSubmit={onSubmit}>
            <div className="line-item-modal">
                <h4 className="text-center mb-3">Mapping Details</h4>
                {bulk ? (
                    <div className="d-flex details mb-2">
                        <TextArea
                            customClass="w-100"
                            name="mapping_items"
                            placeholder="Add bulk mapping item data"
                            rows="10"
                            disabled={isLoading}
                        />
                    </div>
                ) : (
                    <>
                        <div className="d-flex details mb-2">
                            <TextField
                                label="Item"
                                labelClass="col-4"
                                customClass="col-8"
                                name="description"
                                placeholder="Item description"
                                disabled={disabled}
                            />
                        </div>
                        <div className="d-flex details mb-2">
                            <Select
                                label="Unit"
                                labelClass="col-4"
                                customClass="col-8"
                                name="unit"
                                placeholder="Item unit"
                                disabled={disabled}
                                options={Object.keys(UNITS).map((unit) => ({
                                    value: unit.toLowerCase(),
                                }))}
                            />
                        </div>
                        <div className="d-flex details mb-2">
                            <TextField
                                label="Material Cost"
                                labelClass="col-4"
                                customClass="col-8"
                                name="material_cost"
                                type="number"
                                placeholder="Material Cost"
                                disabled={disabled}
                            />
                        </div>
                        <div className="d-flex details mb-2">
                            <TextField
                                label="Labor Cost"
                                labelClass="col-4"
                                customClass="col-8"
                                name="labor_cost"
                                type="number"
                                placeholder="Labor Cost"
                                disabled={disabled}
                            />
                        </div>
                        <div className="d-flex details mb-2">
                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                            <label className="col-4">Is Lump Cost</label>
                            <YesNoRadio
                                name="is_lump_cost"
                                disabled={disabled}
                            />
                        </div>
                        <div className="d-flex details mb-2">
                            <TextField
                                label="Lump Cost"
                                labelClass="col-4"
                                customClass="col-8"
                                name="lump_cost"
                                type="number"
                                placeholder="Lump Cost"
                                disabled={disabled}
                            />
                        </div>
                    </>
                )}
                <div className="text-center mt-4">
                    {!disabled && (
                        <SubmitButton
                            title="Save"
                            className="btn btn-primary px-4"
                            disabled={isLoading}
                        />
                    )}

                    {onDeleteMapping && (
                        <button
                            type="button"
                            className="btn btn-danger"
                            onClick={onDelete}
                            disabled={isLoading}
                        >
                            Delete
                        </button>
                    )}
                </div>
            </div>
        </Form>
    );
}
