/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import PaginatedTable from "../components/PaginatedTable";
import {
    Form,
    Select,
    SubmitButton,
    TextField,
} from "../components/FormElements";
import { modalActions } from "../state/modal";
import { addModel, getModels } from "../state/model";

export const formattedStatus = {
    TRAINED: "Trained",
    ACTIVE: "Active",
    IN_TRAINING: "In training",
    ARCHIEVED: "Archived",
    PROCESSING: "Processing (This may take a while ... )"
};

export default function ModelPage() {
    const dispatch = useDispatch();
    const history = useHistory();

    const { byId, allIds } = useSelector((state) => state.model.allModels);
    const { isLoading } = useSelector((state) => state.model);

    useEffect(() => {
        dispatch(getModels());
    }, []);

    const getFormattedStatus = () => (rowData) =>
        formattedStatus[rowData?.status];

    const getFormattedDate = () => (rowData) =>
        rowData?.created_date?.split("T")[0];

    const columnNames = ["ID", "Version", "Name", "Status", "Created Date"];

    const columns = [
        "id",
        "version",
        "name",
        getFormattedStatus(),
        getFormattedDate(),
    ];

    const handleRedirect = (rowData) => {
        history.push(`/models/${rowData?.id}`);
    };

    const handleAddModel = async (values) => {
        await dispatch(addModel(values));
        dispatch(modalActions.hideModal());
    };

    return (
        <div className="model-page">
            <div className="model-header-section d-flex justify-content-between align-items-center">
                <div className="title">
                    <h1 className="h2 mb-0">Models</h1>
                </div>
                <div className="action-buttons">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() =>
                            dispatch(
                                modalActions.showModal({
                                    modalType: "ADD_MODEL",
                                    modalProps: {
                                        handleAdd: handleAddModel,
                                    },
                                })
                            )
                        }
                    >
                        Add Model
                    </button>
                </div>
            </div>
            <div className="model-body-section mt-5">
                <div className="">
                    {isLoading ? (
                        <div className="mt-3 d-flex justify-content-center">
                            <div
                                className="spinner-border text-primary"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <PaginatedTable
                            columnNames={columnNames}
                            columns={columns}
                            data={byId}
                            rows={allIds}
                            count={allIds.length}
                            onRowClick={handleRedirect}
                            pagination
                            pageSize={20}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export function AddModelModal({ handleAdd }) {
    const dispatch = useDispatch();

    const [initialValues] = useState({
        version: "",
        name: "",
        parent_version: [],
    });

    const { byId, allIds } = useSelector((state) => state.model.allModels);
    const { isLoading } = useSelector((state) => state.model);

    const getOptions = () =>
        allIds
            .filter((id) => byId[id].status !== "ARCHIEVED")
            .map((option) => ({
                label: `v${byId[option].version} - ${byId[option].name}`,
                value: byId[option].id,
            }));

    return (
        <Form initialValues={initialValues} onSubmit={handleAdd}>
            <div className="line-item-modal">
                <h4 className="text-center mb-3">Add New Model</h4>
                <TextField
                    label="Version"
                    name="version"
                    placeholder="Version"
                    disabled={isLoading}
                />
                <TextField
                    label="Name"
                    name="name"
                    placeholder="Name"
                    disabled={isLoading}
                />
                <Select
                    name="parent_version"
                    label="Parent Model"
                    options={getOptions()}
                    disabled={isLoading}
                />
                <div className="text-center mt-4">
                    <button
                        className="btn btn-danger px-4"
                        onClick={() => dispatch(modalActions.hideModal())}
                        type="button"
                        disabled={isLoading}
                    >
                        Cancel
                    </button>
                    <SubmitButton
                        className="btn btn-primary px-4 ml-2"
                        title="Accept"
                    />
                </div>
            </div>
        </Form>
    );
}
