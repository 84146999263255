/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import PaginatedTable from "../components/PaginatedTable";
import { claimsActions, getClaimRoomLineItems } from "../state/claims";
import * as api from "../api";
import AutoComplete from "../components/AutoComplete";
import { modalActions } from "../state/modal";

function ClaimRoomPage() {
    const { claimId, roomId } = useParams();
    const { byId, allIds } = useSelector(
        (state) => state.claims.claimRoomLineItems
    );
    const { byId: mappingsById } = useSelector(
        (state) => state.claims.allMappings
    );
    const { isLoading: spinnerActive } = useSelector((state) => state.claims);
    const [mappedRows, setMappedRows] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getClaimRoomLineItems(claimId, roomId));
    }, []);

    useEffect(() => {
        allIds.forEach(async (id) => {
            if (byId[id].mappings[0]?.mapping_item) {
                const { data: mappingItem } = await api.getMappingItems(
                    byId[id].mappings[0]?.mapping_item
                );
                await dispatch(claimsActions.setMapping(mappingItem));
            }
        });
    }, [allIds]);

    const getMappedItem = () => (rowData) =>
        mappingsById[rowData.mappings[0]?.mapping_item]?.description;

    const getMappingScore = () => (rowData) =>
        rowData.mappings[0]?.score.toLocaleString(undefined, {
            minimumFractionDigits: 4,
        });

    const getARV = () => (rowData) => (
        <span className={rowData.arv < 0 ? "text-danger" : "text-success"}>
            {rowData.arv}
        </span>
    );

    const getData = (data) => (rowData) => rowData[data];

    const columnNames = [
        "Description",
        "Mapped Item",
        "Mapped Score",
        "ARV",
        "RCV",
        "Unit",
        "Unit Cost",
        "Mapped Unit Cost",
        "Quantity",
    ];

    const columns = [
        "description",
        getMappedItem(),
        getMappingScore(),
        getARV(),
        getData("rcv"),
        getData("unit"),
        getData("unit_cost"),
        getData("mapped_unit_cost"),
        getData("quantity"),
    ];

    const onNewMapping = async (mappingId, lineItemText, lineId) => {
        try {
            await api.addMappingAlias(mappingId, lineItemText);
            setMappedRows([...mappedRows, lineId]);
            dispatch(modalActions.hideModal());
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(getClaimRoomLineItems(claimId, roomId));
        }
    };

    const handleViewLineItems = (rowData) => {
        if (rowData?.mappings.length > 0) {
            dispatch(
                modalActions.showModal({
                    modalType: "LINE_ITEMS",
                    modalProps: {
                        data: rowData,
                        onSave: (
                            mappingItemId,
                            lineItemText,
                            lineId,
                            mappingId,
                            newLineItemText,
                            prevLineItemText
                        ) =>
                            dispatch(
                                modalActions.showModal({
                                    modalType: "LINE_ITEM_REPLACE_CONFIRMATION",
                                    modalProps: {
                                        mappingItemId,
                                        lineItemText,
                                        lineId,
                                        mappingId,
                                        addNewMapping: onNewMapping,
                                        claimId,
                                        roomId,
                                        newLineItemText,
                                        prevLineItemText,
                                    },
                                })
                            ),
                    },
                })
            );
        } else {
            dispatch(
                modalActions.showModal({
                    modalType: "LINE_ITEMS",
                    modalProps: { data: rowData, onSave: onNewMapping },
                })
            );
        }
    };

    return (
        <div className="claim-page">
            <div className="claim-header-section d-flex justify-content-between align-items-center">
                <div className="title">
                    <h1 className="h2 mb-0">Claim Room Details</h1>
                </div>
            </div>

            <div className="claim-body-section mt-5">
                <div className="">
                    {spinnerActive ? (
                        <div className="mt-3 d-flex justify-content-center">
                            <div
                                className="spinner-border text-primary"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <PaginatedTable
                            columnNames={columnNames}
                            columns={columns}
                            data={byId}
                            rows={allIds}
                            count={allIds.length}
                            selectedRows={mappedRows}
                            onRowClick={handleViewLineItems}
                            pageSize={20}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export function LineItemModal({ data, onSave }) {
    const [editable, setEditable] = useState(false);
    const [selectedValue, setSelectedValue] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [filteredItems, setFilteredItems] = useState([]);
    const [itemLength, setItemLength] = useState(0);
    const [nextPage, setNextPage] = useState(1);
    const [searchValue, setSearchValue] = useState(1);
    const { byId } = useSelector((state) => state.claims.allMappings);

    const mappedDetails = byId[data?.mappings[0]?.mapping_item];

    const onSubmit = () => {
        setIsLoading(true);
        setFilteredItems([]);
        setItemLength(0);
        setNextPage(1);
        onSave(
            selectedValue?.id,
            data?.description,
            data?.id,
            data?.mappings[0]?.id,
            selectedValue?.description,
            mappedDetails?.description
        );
    };

    const handleSearch = async (value, page) => {
        if (value.length < 4) {
            setFilteredItems([]);
            return;
        }

        const { data: filteredData } = await api.getMappingItems(
            `?search=${value}&page=${page}&status=IN_TRAINING`
        );

        const nextPageString = filteredData?.next;

        const newNextPage = nextPageString
            ?.substring(
                nextPageString?.lastIndexOf("?") + 1,
                nextPageString?.lastIndexOf("&")
            )
            .split("page=")[1];

        if (value === searchValue) {
            setFilteredItems([...filteredItems, ...filteredData.results]);
        } else {
            setFilteredItems(filteredData.results);
        }
        setItemLength(filteredData.count);
        setSearchValue(value);
        setNextPage(newNextPage);
    };

    const delayedSearch = debounce(
        (value, page) => handleSearch(value, page),
        300
    );

    return (
        <div className="line-item-modal">
            <h4 className="text-center mb-3">Line Item Details</h4>
            <div className="d-flex details mb-2">
                <div className="label">Description : </div>
                <div className="desc">{data?.description}</div>
            </div>
            <div className="d-flex details mb-2">
                <div className="label">Mapped : </div>
                <div className="desc">
                    {data?.mappings[0]?.score ? "Yes" : "No"}
                </div>
            </div>

            {data?.mappings[0] && !editable ? (
                <div className="d-flex details mb-2">
                    <div className="label">Mapped Item : </div>
                    <div className="desc">{mappedDetails?.description}</div>
                </div>
            ) : (
                <AutoComplete
                    length={itemLength}
                    nextPage={nextPage}
                    suggestions={filteredItems}
                    setValue={setSelectedValue}
                    handleSearch={delayedSearch}
                    label="Search mapping Item"
                />
            )}

            <div className="text-center mt-4">
                <button
                    className="btn btn-primary px-4"
                    onClick={() =>
                        data?.mappings.length > 0
                            ? selectedValue
                                ? onSubmit()
                                : setEditable(true)
                            : onSubmit()
                    }
                    type="button"
                    disabled={
                        isLoading ||
                        (data?.mappings.length === 0 && !selectedValue)
                    }
                >
                    {data?.mappings.length > 0
                        ? selectedValue
                            ? "Save"
                            : "Edit Mapping"
                        : "Save"}
                </button>
            </div>
        </div>
    );
}

export function ReplaceLineItemConfirmationModal({
    mappingItemId,
    lineItemText,
    lineId,
    mappingId,
    addNewMapping,
    claimId,
    roomId,
    newLineItemText,
    prevLineItemText,
}) {
    const dispatch = useDispatch();

    const onReplaceMapping = async () => {
        dispatch(modalActions.hideModal());
        try {
            await api.deleteMapping(mappingId);
            await addNewMapping(mappingItemId, lineItemText, lineId);
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(getClaimRoomLineItems(claimId, roomId));
        }
    };

    return (
        <div className="confirmation-modal">
            <p className="text-center mb-3">
                You are trying to replace the `<b>{prevLineItemText}</b>` with `
                <b>{newLineItemText}</b>`
            </p>
            <div className="text-center mt-4">
                <h5>Are you sure?</h5>
                <div className="d-flex mt-4">
                    <div className="mx-auto">
                        <button
                            className="btn btn-danger px-4"
                            onClick={() => dispatch(modalActions.hideModal())}
                            type="button"
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-primary px-4 ml-2"
                            onClick={onReplaceMapping}
                            type="button"
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ClaimRoomPage;
