import React, { useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { loadAllUsers } from "../state/user";

import LayoutRoutes from "../routes/LayoutRoutes";
import { loadModelInfo } from "../state/model";

const Layout = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    const { modelInfo } = useSelector((state) => state.model);

    useEffect(() => {
        dispatch(loadAllUsers());
        dispatch(loadModelInfo());
    }, [dispatch]);

    return (
        <div>
            <nav className="navbar navbar-primary sticky-top bg-primary flex-md-nowrap p-0">
                <Link
                    to="/"
                    className="navbar-brand bg-primary col-sm-3 col-md-2 mr-0"
                >
                    <img src="/assets/logo.png" alt="" />
                </Link>
                <div className="d-flex px-3">
                    {modelInfo && (
                        <>
                            <div className="mx-3 text-white">
                                <span className="d-flex">
                                    <span className="mx-auto">
                                        Active Model
                                    </span>
                                </span>
                                <div className="d-flex">
                                    <span className="badge badge-secondary mx-auto">
                                        {`v${modelInfo?.active?.version} - ${modelInfo?.active?.name}`}
                                    </span>
                                </div>
                            </div>
                            <div className="mx-3 text-white">
                                <div className="d-flex">
                                    <span className="mx-auto">
                                        Training Model
                                    </span>
                                </div>
                                <div className="d-flex">
                                    <span className="badge badge-secondary mx-auto">
                                        {modelInfo?.training?.id
                                            ? `v${modelInfo?.training?.version} - ${modelInfo?.training?.name}`
                                            : "No in-training model"}
                                    </span>
                                </div>
                            </div>
                        </>
                    )}
                    <div className="nav-item text-nowrap my-auto ml-3">
                        <Link className="nav-link text-white" to="/signout">
                            Sign Out
                        </Link>
                    </div>
                </div>
            </nav>

            <div className="container-fluid">
                <div className="row">
                    <nav className="col-md-2 d-none d-md-block bg-light sidebar">
                        <div className="sidebar-sticky">
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <Link
                                        className={
                                            location.pathname === "/"
                                                ? "nav-link active"
                                                : "nav-link"
                                        }
                                        to="/"
                                    >
                                        Claims
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className={
                                            location.pathname === "/models"
                                                ? "nav-link active"
                                                : "nav-link"
                                        }
                                        to="/models"
                                    >
                                        Models
                                    </Link>
                                </li>
                                {modelInfo?.training?.id && (
                                    <li className="nav-item">
                                        <Link
                                            className={
                                                location.pathname ===
                                                "/mapping-items"
                                                    ? "nav-link active"
                                                    : "nav-link"
                                            }
                                            to="/mapping-items"
                                        >
                                            Mapping Items
                                        </Link>
                                    </li>
                                )}
                                <li className="nav-item">
                                    <Link
                                        className={
                                            location.pathname === "/macro-items"
                                                ? "nav-link active"
                                                : "nav-link"
                                        }
                                        to="/macro-items"
                                    >
                                        Macro Items
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </nav>
                    <main
                        role="main"
                        className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-5 pt-5"
                    >
                        <LayoutRoutes />
                    </main>
                </div>
            </div>
        </div>
    );
};

export default Layout;
