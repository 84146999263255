/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import {
    getMappingItem,
    removeMappingAlias,
    removeMappingItem,
} from "../state/claims";
import PaginatedTable from "../components/PaginatedTable";
import {
    Form,
    Select,
    SubmitButton,
    TextField,
    YesNoRadio,
} from "../components/FormElements";
import UNITS from "../constants";
import { addMappingAlias } from "../api";

export default function MappingItemPage() {
    const { selectedMappingItem, isLoading } = useSelector(
        (state) => state.claims
    );
    const { allIds, byId } = useSelector((state) => state.claims.allAliases);
    const [aliasFormData] = useState({});

    const { mappingId } = useParams();

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(getMappingItem(mappingId));
    }, []);

    const onDeleteMapping = async () => {
        await dispatch(removeMappingItem(mappingId));
        history.push("/mapping-items");
    };

    const deleteAlias = async (id) => {
        await dispatch(removeMappingAlias(mappingId, id));
    };

    const getDeleteButton = (rowData) => (
        <button
            type="button"
            className="btn btn-danger"
            onClick={() => deleteAlias(rowData.id)}
            disabled={isLoading}
        >
            Delete
        </button>
    );

    const onAddAlias = async (values) => {
        try {
            await addMappingAlias(mappingId, values.alias);
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(getMappingItem(mappingId));
        }
    };

    const columnNames = ["ID", "Description", "Delete"];

    const columns = ["id", "description", getDeleteButton];

    return (
        <div className="claim-page">
            <div className="claim-header-section d-flex justify-content-between align-items-center">
                <div className="title">
                    <h1 className="h2 mb-0">Mapping Item</h1>
                </div>
                <div className="action-buttons">
                    <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => onDeleteMapping()}
                        disabled={isLoading}
                    >
                        Delete
                    </button>
                </div>
            </div>

            <div className="claim-body-section mt-5">
                <div className="">
                    {isLoading ? (
                        <div className="mt-3 d-flex justify-content-center">
                            <div
                                className="spinner-border text-primary"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <>
                            {selectedMappingItem && (
                                <Form
                                    initialValues={{ ...selectedMappingItem }}
                                >
                                    <div className="mt-4">
                                        <div className="d-flex details mb-2">
                                            <TextField
                                                label="Item"
                                                labelClass="col-4 pl-0 font-weight-bold"
                                                customClass="col-8"
                                                name="description"
                                                placeholder="Item description"
                                                disabled
                                            />
                                        </div>
                                        <div className="d-flex details mb-2">
                                            <Select
                                                label="Unit"
                                                labelClass="col-4 pl-0 font-weight-bold"
                                                customClass="col-8"
                                                name="unit"
                                                placeholder="Item unit"
                                                disabled
                                                options={Object.keys(UNITS).map(
                                                    (unit) => ({
                                                        value: unit.toLowerCase(),
                                                    })
                                                )}
                                            />
                                        </div>
                                        <div className="d-flex details mb-2">
                                            <TextField
                                                label="Material Cost"
                                                labelClass="col-4 pl-0 font-weight-bold"
                                                customClass="col-8"
                                                name="material_cost"
                                                type="number"
                                                placeholder="Material Cost"
                                                disabled
                                            />
                                        </div>
                                        <div className="d-flex details mb-2">
                                            <TextField
                                                label="Labor Cost"
                                                labelClass="col-4 pl-0 font-weight-bold"
                                                customClass="col-8"
                                                name="labor_cost"
                                                type="number"
                                                placeholder="Labor Cost"
                                                disabled
                                            />
                                        </div>
                                        <div className="d-flex details mb-2">
                                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                            <label className="col-4 pl-0 font-weight-bold">
                                                Is Lump Cost
                                            </label>
                                            <YesNoRadio
                                                name="is_lump_cost"
                                                disabled
                                            />
                                        </div>
                                        <div className="d-flex details mb-2">
                                            <TextField
                                                label="Lump Cost"
                                                labelClass="col-4 pl-0 font-weight-bold"
                                                customClass="col-8"
                                                name="lump_cost"
                                                type="number"
                                                placeholder="Lump Cost"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </Form>
                            )}
                            {/* {allIds.length > 0 && ( */}
                            <>
                                <div className="title">
                                    <h5 className="h5 mb-3 mt-5">Aliases</h5>
                                </div>
                                <Form
                                    initialValues={aliasFormData}
                                    enableReinitialize
                                    onSubmit={onAddAlias}
                                >
                                    <div className="input-group mb-4">
                                        <TextField
                                            name="alias"
                                            type="text"
                                            placeholder="Enter alias text "
                                        />

                                        <div className="input-group-btn">
                                            <SubmitButton
                                                className="btn btn-primary ml-4"
                                                title="Add Alias"
                                            />
                                        </div>
                                    </div>
                                </Form>
                                <PaginatedTable
                                    columnNames={columnNames}
                                    columns={columns}
                                    data={byId}
                                    rows={allIds}
                                    count={allIds.length}
                                    pagination
                                    pageSize={20}
                                />
                            </>
                            {/* )} */}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
